import React from "react";
import "./App.css";
import "./pages/home/home.css";
import Approuter from "./approuter";


function App() {
    return (
        <div className="App">
            <header/>
            <Approuter/>
        </div>
    );
}

export default App;
